import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from 'gatsby';

import SEO from "../../components/seo";
import ProjectLayout from "../../components/layouts/projectLayout";
import Banner from "../../components/project/banner";
import ProjectSection from "../../components/project/projectSection";
import ProjectFooter from "../../components/project/projectFooter";
import ScreenshotsContainer from "../../components/project/screenshotsContainer";
import {ProjectSectionTitle, ProjectSectionsubtitle, ProjectSectionDescription} from "../../components/project/projectTypography";
import {MigoSoftware, Slingshot, Branding, Restart, RefreshExperience} from "../../components/img/syncablesImages";


import Colors from "../../constants/colors";
import Breakpoints from "../../constants/breakpoints";

// Images
import BannerImage from "../../images/project_banners/Project_banner-Syncables.png";
import HierarchyImage from "../../images/projects/Syncables/design_process/Design_process-Info_hierarchy.png";
import SpecImage from "../../images/projects/Syncables/design_process/Design_process-Spec.png";
import MockupsImage from "../../images/projects/Syncables/design_process/Design_process-Hifi_mockups.png";

const getProject = graphql`
  query {
    project:allContentfulProject(filter: {title:{eq: "Syncables"}}) {
      edges {
        node {
          title
          description {
            description
          }
          slug
          order
          year
          role
          contentful_id
        }
      }
    }
  }
`;

const DesignProcessList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, minmax(10rem, 27rem));
  grid-gap: 2.5rem;
  justify-content: center;

  list-style: none;
  margin: 0;


  @media only screen and (max-width: ${Breakpoints.Width.laptop_small}) {
    grid-gap: 1.5rem;
  }

  @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
    grid-gap: 1rem;
  }

  @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
    grid-template-columns: 1fr;
  }
  
  li {
    display: block;
    background-color: ${Colors.Basic.white};
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
    padding: 24px 24px 60% 24px;
    position: relative;

    &.design_process-image {
      background-position: center center;
      background-size: 92%;
      background-repeat: no-repeat;
    }

    &.design_process-image-spec {
      background-image: url(${SpecImage});
    }

    &.design_process-image-hierarchy {
      background-image: url(${HierarchyImage});
    }

    &.design_process-image-mockups {
      background-image: url(${MockupsImage});
    }
  }
`;


const Syncables = () => {
  const data = useStaticQuery(getProject);
  const project = data.project.edges[0].node;
  const sections = [
    {
      "name": "Migo Software",
      "to": "migo-software"
    },
    {
      "name": "Restart",
      "to": "restart"
    },
    {
      "name": "Syncables 360",
      "to": "syncables360"
    }
  ]

  return (  
    <ProjectLayout projectTitle={project.title} sections={sections}>
      <SEO title="Project - Koopio" />
      <Banner
        bgColor="#D5D7E2"
        bgImage={BannerImage}
        bgSize="70%"
        title={project.title}
        description={project.description.description}
        year={project.year}
        role={project.role}
      />

      <ProjectSection bgColor={Colors.Basic.gray_95} description="false" name={sections[0].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[0].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Sync PC data to portable device and take it to go
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description">
            <ProjectSectionDescription>
              Enjoy the flexibility and freedom to work on any PC. Automatically organizes emails, calendars, bookmarks, documents and more and makes syncing to any networked PC or portable device. 
            </ProjectSectionDescription>
          </div>
        </div>
        <ScreenshotsContainer titleOffsetY="32px" infoWidth="25%">
          <div className="info">
            <h4>Mobile Desktop</h4>
            <p>Migo Mobile Desktop organize and sync users’ device.</p>
          </div>
          <MigoSoftware></MigoSoftware>
        </ScreenshotsContainer>
        <ScreenshotsContainer titleOffsetY="32px" infoWidth="25%">
          <div className="info">
            <h4>Slingshot</h4>
            <p>Migo Slingshot let users easily sync music with different device.</p>
          </div>
          <Slingshot></Slingshot>
        </ScreenshotsContainer>
        <ScreenshotsContainer titleOffsetY="32px" infoWidth="25%">
          <div className="info">
            <h4>Branding</h4>
            <p>Create new application icons and redesign the website.</p>
          </div>
          <Branding></Branding>
        </ScreenshotsContainer>
      </ProjectSection>

      <ProjectSection name={sections[1].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[1].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Concentrate on the strength
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description">
            <ProjectSectionDescription>
              Focus on one great product instead of several mediocre ones. We rebuild the application and redesign the whole identity and user experience.
            </ProjectSectionDescription>
          </div>
        </div>
        <Restart></Restart>
      </ProjectSection>

      <ProjectSection bgColor="#EBEBEB" noDescription name={sections[2].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[2].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Reimagine the experience of synchronization
            </ProjectSectionsubtitle>
          </div>
        </div>
        <ScreenshotsContainer column infoWidth="100%">
          <div className="info">
            <h4>Design Process</h4>
            <p>Break down and analyze the details of the application, and then redesign the user experience.</p>
          </div>
          <DesignProcessList>
            <li className="design_process-image design_process-image-hierarchy" data-sal="slide-up" data-sal-delay="300" data-sal-duration="1200">
            </li>
            <li className=" design_process-image design_process-image-spec" data-sal="slide-up" data-sal-delay="450" data-sal-duration="1200">
            </li>
            <li className=" design_process-image design_process-image-mockups" data-sal="slide-up" data-sal-delay="600" data-sal-duration="1200">
            </li>
          </DesignProcessList>
        </ScreenshotsContainer>
        <ScreenshotsContainer column infoWidth="100%">
          <div className="info">
            <h4>Refresh Experience</h4>
            <p>Redesign the UI to let information and functions to be easily discovered and used.</p>
          </div>
          <RefreshExperience></RefreshExperience>
        </ScreenshotsContainer>
      </ProjectSection>

      <ProjectFooter currentProjectOrder="6">
      </ProjectFooter>
    </ProjectLayout>
  )
};

export default Syncables;
