import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from 'styled-components';

import Colors from '../../constants/colors';
import Breakpoints from "../../constants/breakpoints";

const getData = graphql`
  query {
    migoSoftware: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Syncables/Migo_Desktop" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    slingshot: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Syncables/Slingshot" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    branding: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Syncables/branding" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    restart: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Syncables/restart" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    refreshExperience: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Syncables/refresh_experience" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

function withImageData(WrappedComponent) {
  return props => (
    <StaticQuery
      query={getData}
      render={data => <WrappedComponent {...props} imageData={data} />}
    />
  );
}

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
    flex-direction: column;
  }

`;

const MigoSoftwareImages = styled(ImageContainer)`
  > div {
    &:nth-child(1) {
      width: 49.7409%;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
        max-width: 100%;
      }
    }

    &:nth-child(2) {
      width: 44.7323%;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
        max-width: 100%;
      }
    }

    &:not(:last-child) {
      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
`;

const SlingshotImages = styled(ImageContainer)`
  > div {
    width: 31%;

    @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
      width: 100%;
      max-width: 100%;
    }

    &:not(:last-child) {
      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
`;

const BrandingImages = styled(ImageContainer)`
  justify-content: space-around;

  > div {
    &:nth-child(1) {
      width: 39.4193%;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
        max-width: 100%;
      }
    }

    &:nth-child(2) {
      width: 47.1803%;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
        max-width: 100%;
      }

      img {
        border: 1px solid ${Colors.Basic.gray_88};
      }
    }

    &:not(:last-child) {
      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
`;

const RestartImages = styled(ImageContainer)`
  display: grid;
  grid-template-rows: 160px 180px 80px;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4px;

  @media only screen and (max-width: ${Breakpoints.Width.project_medium}) {
    grid-template-columns: 45% 55%;
  }

  @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 2px;
  }

  > div {
    &:nth-child(1) {
      grid-row: 1 / 3;
      grid-column: 1 / 2;

      @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
        grid-row: 1 / 2;
      }
    }
    &:nth-child(2) {
      grid-row: 3 / 4;
      grid-column: 1 / 2;

      @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
        grid-row: 4 / 5;
      }
    }
    &:nth-child(3) {
      grid-row: 1 / 2;
      grid-column: 2 / 3;

      @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
      }
    }
    &:nth-child(4) {
      grid-row: 2 / 4;
      grid-column: 2 / 3;
      
      @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
        grid-row: 3 / 4;
        grid-column: 1 / 2;
      }
    }
  }
`;

const RefreshExperienceImages = styled(ImageContainer)`
  @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
    flex-direction: column;
  }
  > div {
    &:nth-child(1) {
      width: 30.7639%;

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        width: 100%;
        max-width: 100%;
      }
    }

    &:nth-child(2), &:nth-child(3) {
      width: 31.8651%;

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        width: 100%;
        max-width: 100%;
      }

    }

    &:not(:last-child) {
      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
`;

export const MigoSoftware = withImageData(props => (
  <MigoSoftwareImages lightText data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
    {props.imageData.migoSoftware.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </MigoSoftwareImages>
));

export const Slingshot = withImageData(props => (
  <SlingshotImages lightText data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
    {props.imageData.slingshot.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </SlingshotImages>
));

export const Branding = withImageData(props => (
  <BrandingImages lightText data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
    {props.imageData.branding.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </BrandingImages>
));

export const Restart = withImageData(props => (
  <RestartImages lightText data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
    {props.imageData.restart.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </RestartImages>
));

export const RefreshExperience = withImageData(props => (
  <RefreshExperienceImages lightText data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
    {props.imageData.refreshExperience.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </RefreshExperienceImages>
));